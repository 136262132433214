.fixed-height-card {
    width: 17rem;
    border: 1px solid black;
    display: flex;        
    flex-direction: column; 
}

.fixed-height-card .card-img-top {
    height: 100px;
    object-fit: cover;
}

.fixed-height-card .card-content {
    flex: 1;         
    display: flex;
    flex-direction: column; 
}

.player-card {
    width: 17rem;
    height: 97%; 
    border: 2px solid rgb(45, 43, 43);
}

.player-card-img {
    height: 200px; 
    object-fit: cover; 
}


.fixed-height-card {
    width: 15rem;
    height: 20rem; /* Adjust based on your preference */
}

.card-image {
    height: 50px; /* Adjust based on your preference */
    width: 100%;
    object-fit: cover; 
}

.tool-link {
    text-decoration: none;
    color: black;
}

.tool-description {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3; /* Number of lines to show */
    -webkit-box-orient: vertical;
}

.card-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(18rem, 1fr)); /* Create a grid with columns of minimum 18rem width */
    gap: 20px; /* Add gap between cards */
  }

.model-card {
  width: 18rem;
  height: 100%; /* Ensure all cards have the same height */
  display: flex;
  flex-direction: column;
}

.model-card .card-img {
    flex-grow: 1; 
    object-fit: cover; 
}

.model-card .card-body {
  flex-grow: 0; /* Prevent body from growing */
}
